#main-instructor-profile-container {
  /* display: flex;
  flex-direction: column; */
  /* padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 600px;
  margin: auto; */
  /* background-color: #ffffff; */
  background-color: transparent;
}

.instructor-input-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.instructor-input-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
  padding: 10px;
}

.instructor-form-input-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.instructor-form-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.instructor-profile-submit-button {
  border-radius: 20px;
  /* background-color: #008cba; */
  background-color: #023047;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  display: block;
  /* padding: 10px 20px;
  border: none;
  font-size: 16px;
  margin: 10px 0;
  display: block;
  width: 100%;
  text-align: center; */
}
.instructor-profile-submit-button:hover {
  /* color: #005f5f; */
  color: #023047;
  background-color: white;
  border: 1px solid #023047;
}
