/* styles.css */
.course-recommender-section {
  background-color: #f0f8ff; /* Light background color */
  border-radius: 8px; /* Rounded corners */
  padding: 2rem; /* Add padding */
}

.course-recommender-section h2 {
  color: #333; /* Dark text color for contrast */
  font-weight: bold; /* Bold heading */
}

.course-recommender-section p {
  /* font-weight: 400; */
  color: #000000; /* Slightly lighter text color */
}

.course-recommender-section .Link {
  /* background-color: #28a745; Success button color */
  border: none; /* Remove default border */
  text-decoration: none;
  padding: 0.75rem 1.5rem; /* Adjust button padding */
  font-size: 1.25rem; /* Larger text size for readability */
  border-radius: 5px; /* Rounded corners for the button */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}
