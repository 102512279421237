.video-section-player-div {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  flex: 1 1 30%;
  margin: 20px;
  /* min-width: 300px; */
  height: auto;
}
@media screen and (max-width: 768px) {
  .video-section-player-div {
    flex: 1 1 100%;
    margin: 10px 0;
    flex-direction: column;
  }
}
