.order-confirm-container {
  /* background-color: #343a40; */
  background-color: bisque;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 450px;
  margin: 25px;
  /* width: max-content; */
  justify-content: space-between;
  top: 180px;
  right: 10vw;
  /* position: fixed; */
  /* z-index: 10; */
  /* height: 12vw; */
  height: fit-content;
  /* height: 50%; */
  /* height: calc(70vh - 130px); */
  /* overflow-y: auto; */
}

.order-confirm-card {
  background: #023047;
  /* background: #2c3136; */
  border: none;
}

.confirm-section-title {
  color: #adb5bd;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.order-confirm-section {
  margin-top: 20px;
}
