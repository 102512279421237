/* AboutUs.css */
.about-us-container {
  /* padding: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
}

.about-us-content {
  max-width: 800px;
  background: white;
  padding: 20px;
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

.about-us-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.about-us-content p {
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}
