/* src/components/StudentLessons.css */
.student-lessons {
  padding: 2rem;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* margin: 0 auto; */
}
.student-lessons-cards-container {
  display: flex;
  flex-direction: column;
}
.lesson-card {
  width: fit-content;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.lesson-actions {
  margin: 1rem 0;
}

.note-section {
  margin-top: 1rem;
}
