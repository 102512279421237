.set-password-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: fit-content;
  margin: 10px;
  /* background-color: #f5f5f5; */
}
.set-password-formContainer {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.button {
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #ffb703;
  color: #fff;
  cursor: pointer;
}

.message {
  margin-top: 10px;
  color: #050000;
}
