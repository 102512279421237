.menu-container {
  /* padding-top: 10px; */
  height: 50px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
}
.menu-container .p-menubar {
  width: auto; /* Adjust width as needed */
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.menu-container .p-menubar-root-list {
  display: flex;
  justify-content: center; /* Center the menu items */
  margin-bottom: 0px !important;
}
.p-menubar {
  border: none !important; /* Remove border */
}
.p-menubar-end {
  padding-bottom: 15px;
}
.contact-container {
  color: #ffffff;
}
.p-menubar .p-menuitem-link {
  text-decoration: none;
}
.item-label {
  color: white;
  font-weight: 700;
  font-size: 20px;
}
/* Ensure text decoration is removed even on hover */
.p-menubar .p-menuitem-link:hover {
  /* transform: scale(1.05);
  color: rgb(255, 255, 255) !important; */
  /* background-color: #023047; */
  /* margin-top: 5px; */
  /* background-color: rgb(202, 202, 202);
  padding-top: 10px;
  border-color: #007ad9;
  text-decoration: none;
  border-radius: 9px; */
}
/* .courses-dropdown-div {
  display: flex;
  flex: 1;
} */

.courses-dropdown {
  cursor: pointer;
  /* font-weight: 600; */
  /* margin-left: -18px; */
  margin-top: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: medium;
}
.courses-dropdown:hover {
  /* margin-left: -18px; */
  /* background-color: #ededed; */
  /* text-decoration: underline; */
}
.p-submenu-list {
  width: 200px;
  border-radius: 10px;
  /* background-color: #f5f5f5; Change dropdown background color */
  /* border: 1px solid #007ad9; Change dropdown border color */
  border: 1px solid #e7e7e7;
  z-index: 2;
}
.p-menuitem-content:hover {
  /* background-color: transparent;
  text-decoration: underline; */
  transform: scale(1.01);
}
.p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
  background-color: transparent;
}
.p-menubar
  .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
  > .p-menuitem-content {
  background-color: transparent;
}

.auth-buttons {
  /* border: none !important; */
  /* background-color: transparent !important; */
  /* box-shadow: none !important; */
  /* font-weight: bolder; */
}
.dropdown-menubar {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(
    255,
    255,
    255
  ); /* Adjust the background color as needed */
  z-index: 1000; /* Ensure it's on top of other elements */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for a dropdown effect */
  border-radius: 4px; /* Optional: Add border radius */
  overflow: hidden;
  width: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: auto; Adjust width to fit the content */
}

.dropdown-menubar .p-menubar {
  border: none; /* Remove default border */
}

.dropdown-menubar .p-menubar-root-list {
  display: flex;
  padding-left: 0px;
  /* z-index: 1001; */
  flex-direction: column; /* Stack menu items vertically */
}

.dropdown-menubar .p-menubar-root-item {
  padding: 10px; /* Adjust padding for menu items */
}

.dropdown-menubar .p-menubar-root-item:hover {
  background-color: #f0f0f0; /* Hover effect */
}
.header-megamenu {
  border: none;
}
.p-megamenu-root-list {
  margin-bottom: 0px;
}
.p-megamenu-submenu {
  padding: 8px;
}
.p-megamenu-grid .p-submenu-list {
  border: none;
  width: max-content;
}
.p-menuitem-content:hover {
  text-decoration: none;
}

/* Change the background color of the toggle button on hover */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .menu-container {
    height: max-content;
  }
}
@media screen and (min-width: 768px) and (max-width: 960px) {
  .p-menubar-button {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .start {
    margin-right: 0px;
    /* justify-content: center;
    display: flex;
    flex-direction: row; */
  }
  .menubar-mobile-outer-div {
    display: flex;
    justify-content: space-between; /* Distribute space between logo and menu button */
    align-items: center; /* Center elements vertically */
    padding: 0 1rem; /* Add some padding for breathing room */
    background-color: white; /* Set background color to white */
    width: 100%; /* Full width to prevent overflow */
    position: relative;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    /* display: inline; */
    /* padding: 20px; */
  }
  .menubar-mobile-outer-div .p-component {
    /* height: 2000px; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .big-screen-header {
    display: none;
  }
  /* For mobile phones: */
  .logo {
    /* margin-left: 20px; */
    min-width: 120px;
    width: 30vw;
    height: 60px;
  }
  .mobile-header-booknow {
    margin-left: 10px; /* Add some space between the menu and the Book Now button */
    align-self: center;
    /* margin-left: 10vw;
    margin-right: -10vw;
    width: 75px;
    font-size: 13px; */
  }
  .custom-menu-button {
    color: aquamarine;
  }
  .contacts {
    display: none;
  }
  .menubar-button .p-menubar-button {
    border-radius: 0%;
    color: white;
    /* background-color: #023047; */
    background-color: #219ebc;
  }
  .menubar-button .p-menubar-root-list {
    width: max-content;
  }
  /* .menubar-button-wrapper {
    display: block;
    position: relative; /* Ensure dropdown opens normally */
  /* } */

  /* .menu-button {
    display: inline-block;
  }

  .menubar-button .p-menubar {
    width: auto;
  }

  .menubar-button .p-menubar-root-list {
    display: block;
  } */

  /* .contact-container {
    margin-left: 0%;
  } */
  /* .header-email {
    display: none;
  }
  .header-phone {
    display: none;
  } */
  .p-menubar-end {
    display: none !important;
  }
  .p-menubar .p-menuitem-link {
    text-decoration: none;
  }
  .item-label {
    color: black;
  }
  .menu-item-text {
    color: black;
    background-color: #023047;
  }
  /* Ensure text decoration is removed even on hover */
  .p-menubar .p-menuitem-link:hover {
    text-decoration: none;
  }
  .menubar-mobile-outer-div .p-menubar-button {
    background-color: transparent;
    outline: none; /* Remove the white outline */
    color: #4d4d4d;
    margin-left: auto; /* Push the button to the right */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
  }
  .start-button {
    background-color: orange;
    color: "white";
  }
  .start-button :hover {
    background-color: rgb(255, 255, 255);
    color: orange;
  }
  /* Change the background color of the toggle button on hover */
  .p-menubar-button:hover {
    background-color: #d6cbcb; /* Example hover color */
    outline: white 5px;
  }
  .course-heading-mobile {
    color: black;
  }
  .p-menubar .p-menuitem-link:hover {
    /* transform: scale(1.05); */
    /* color: rgb(150, 47, 47);
    background-color: #e3ebef;
    margin-top: 5px;
    width: 80%; */
    /* background-color: rgb(31, 96, 140); */
    /* padding-top: 10px;
    border-color: #007ad9;
    border-radius: 9px; */
  }
  .dropdown-menubar {
    position: absolute;
    top: 100%;
    left: -100%;
    right: 600%;
    background-color: rgb(
      255,
      255,
      255
    ); /* Adjust the background color as needed */
    z-index: 1000; /* Ensure it's on top of other elements */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for a dropdown effect */
    border-radius: 4px; /* Optional: Add border radius */
    overflow: visible;
    width: max-content;
    width: 500px;
  }
  .dropdown-menubar .p-menubar {
    padding-right: 1px;
  }
  .context-menu-item {
    padding: 0;
    font-size: small;
    width: 100%;
  }
}
.context-menu-item .p-menuitem-link {
  padding: 5px;
}
.start {
  margin-right: 160px;
}
@media (max-width: 576px) {
  .menubar-mobile-outer-div {
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
  }
  .menubar-mobile-outer-div .p-menubar-button {
    margin-left: 50px;
  }
}
@media (max-width: 314px) {
  .menubar-mobile-outer-div .p-menubar-button {
    margin-left: 25px;
  }
}
