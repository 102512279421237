Sidebar.css .sidebar {
  display: flex;
  flex-direction: column;
  width: 12%;
  height: 100vh;
  background-color: #219ebc;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 0.3s ease;
}
.sidebar-link-container {
  display: flex;
  align-items: center;
}
.sidebar-link-container:hover {
  background-color: #374151;
  cursor: pointer;
}

.sidebar-link {
  display: flex;
  align-items: center;
}

.sidebar-toggle-icon {
  cursor: pointer;
}
.sidebar.collapsed {
  width: 60px;
}
.p-menuitem-link {
  text-decoration: none;
}
.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  background-color: #219ebc;
  height: fit-content;
}

.sidebar-header .logo {
  max-width: 100px;
}

.sidebar.collapsed .logo {
  display: none;
}

.sidebar-header .collapse-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.sidebar-content {
  flex-grow: 1;
  padding: 20px 0;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  margin-bottom: 20px;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.sidebar-link:hover {
  background-color: #374151;
}

.sidebar-icon {
  margin-right: 15px;
  font-size: 20px;
}

.sidebar.collapsed .sidebar-icon {
  margin-right: 0;
  text-align: center;
  width: 100%;
}

.sidebar-label {
  transition: opacity 0.3s ease;
}

.sidebar.collapsed .sidebar-label {
  opacity: 0;
  pointer-events: none;
}

.sidebar-footer {
  padding: 20px;
}

.sidebar-footer .sidebar-link {
  color: #e3e3e3;
}

.sidebar-footer .sidebar-link:hover {
  background-color: #374151;
}
@media screen and (max-width: 768px) {
  .sidebar {
    z-index: 1000;
  }
}
/* Sidebar Styles */
.sidebar {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  background-color: #219ebc;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden off-screen */
  transition: left 0.5s ease;
  z-index: 1000;
}

/* Open sidebar on mobile */
.sidebar.open {
  left: 0; /* Slide in from left */
}

.sidebar.collapsed {
  width: 60px;
}

/* Mobile Toggle Button */
.toggle-btn {
  position: fixed;
  top: 5px;
  left: 10px;
  z-index: 1001;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }
  .toggle-btn {
    top: 10px;
  }
}
/* Hide toggle button on larger screens */
@media screen and (min-width: 769px) {
  /* .toggle-btn {
    display: none;
  } */
}
