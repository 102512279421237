.jobs-details-modal .p-dialog-content {
  padding: 25px;
}

.jobs-details-modal .p-tabmenu {
  border-bottom: 1px solid #dee2e6;
}

.jobs-details-modal .p-tabmenu-nav {
  justify-content: center;
}

.jobs-details-modal .tab-content {
  padding: 20px;
}

.jobs-details-modal table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.jobs-details-modal th,
.jobs-details-modal td {
  border: 1px solid #dee2e6;
  padding: 8px;
  text-align: left;
}

.jobs-details-modal .order-summary {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.jobs-details-modal .modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.jobs-details-modal .progress-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobs-details-modal .progress-summary {
  display: flex;
  flex-direction: column;
}

.jobs-details-modal .progress-item {
  margin-bottom: 10px;
}

.jobs-details-modal .progress-label {
  font-weight: bold;
}

.jobs-details-modal .progress-value {
  font-size: 24px;
  margin-top: 5px;
}

.jobs-details-modal .progress-chart {
  position: relative;
  width: 100px;
  height: 100px;
}

.jobs-details-modal .chart-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
}
.availability-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}
.availability-table th,
.availability-table td {
  border: 1px solid #219ebc;
  padding: 10px;
  text-align: center;
}
.availability-table th {
  color: #ffb703;
  background-color: #023047;
}
.availability-table td {
  background-color: #023047;
  /* background-color: #fb8500; */
  color: white;
}
