.footer-outer-container {
  /* background-image: url("../Assets/LogoFullWhite.webp"); */
  width: 100%;
  background-color: #023047;

  background: repeating-linear-gradient(90deg, #023047, #8ecae6);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  /* color: white; */
}
.footer-container {
  /* margin-left: 30px;
  
  margin-right: 30px; */
}
.footer-logo-container {
  /* margin-right: 100px;
  margin-left: 50px; */
  text-align: left;
  margin-right: 100px;
  /* margin-left: 20px; */
  margin-left: 250px;
}
.nav-item {
  /* font-weight: 600; */
}
.footer-icon {
  flex-shrink: 0; /* Prevents the icon from shrinking */
  margin-right: 0.25rem;
}

.footer-contact-text {
  /* flex: 1; Allows the email to occupy the remaining space */
  /* word-break: break-word; Ensures long words or emails wrap correctly */
  white-space: normal; /* Allows wrapping for long text */
  /* overflow-wrap: anywhere; Allows breaking at any point to fit content */
}
@media (max-width: 768px) {
  .footer-logo {
    height: 40px;
  }
  .footer-logo-container {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    margin-left: 0px;
  }
  .footer-container {
    margin-left: 0px;
    margin-right: 0px;
  }
}
