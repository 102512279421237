/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Vidaloka&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

.benifits-section {
  display: flex;
  align-items: center;
  margin-left: 200px;
  justify-content: space-between;
  margin-bottom: 40px;
  font-family: "Poppins", sans-serif;
}
.benefits-item {
  width: 400px;
  height: 500px;
  margin: 10px;
  outline: 1px lightgrey solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* align-items: stretch;
  justify-content: stretch; */
}
.image-container {
  margin-top: 20px;
  width: 100%;
  /* height: 250px; */
  max-height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 15px;
}

.benefit-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: block;
}

.text-card-heading {
  margin: 20px 0px 20px 0px;
  font-weight: 900;
  font-size: 25px;
  height: 10%;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}
.text-card {
  flex: 1;
  margin: 10px;
  font-size: 17px;
  /* text-align: justify; */
  justify-content: flex-end;
  font-family: "Poppins", sans-serif;
}

/* .square-box {
  width: 347px;
  height: 190px;
  background-color: #ccc;
  margin-top: 9px;
  margin-bottom: 9px;
  text-align: center;
  border: 1px solid rgba(218, 141, 59, 0.439);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  box-shadow: 2px 2px 2px 2px #888888;
}
.square-box-benifits {
  width: 347px;
  height: 190px;
  background-color: #ccc;
  justify-content: center;
  align-items: center;
  border: 1px solid orange;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 2px 2px #888888;
}
.square-box-benifits-heading {
  margin-top: 30px;
  width: 337px;
  height: 190px;
  background-color: #ccc;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-family: "Inter", sans-serif;
} */
/* .square-box-benifits div {
  font-weight: bold;
  text-align: center;
}
.circle {
  margin-bottom: 40px;
  width: 50px;
  height: 50px;
  background-color: orange;
  border-radius: 52%;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.benifit-text {
  font-size: 20px;
  font-family: "Inter", sans-serif;
} */
@media (max-width: 768px) {
  .benifits-section {
    margin: 0;
    flex-direction: column;
    /* flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 50px;
    padding: 0;
    margin: 0; */
  }

  .benefits-item {
    width: 80%;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    margin-bottom: 20px;
  }
  .flexible-hours {
    height: 600px !important;
  }
  .top-2 {
    width: 320px;
    height: 200px;
    align-items: center;
    justify-content: center;
    /* margin-left: -350px; */
  }
  .bottom-2 {
    width: 320px;
    height: 200px;
  }
}

/* App.css */
/* .benefits-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
}

.benefits-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.square-box,
.square-box-benefits {
  width: 100%;
  max-width: 347px;
  height: auto;
  background-color: #ccc;
  text-align: center;
  margin: 9px 0;
  border: 1px solid rgba(218, 141, 59, 0.439);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  box-shadow: 2px 2px 2px 2px #888888;
}

.square-box-benefits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.square-box-benefits div {
  font-weight: bold;
  text-align: center;
}

.circle {
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
  background-color: orange;
  border-radius: 50%;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.benefit-text {
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

@media (max-width: 768px) {
  .benefits-section {
    margin: 0 auto;
  }

  .benefits-item {
    width: 100%;
    padding: 0 10px;
  }

  .square-box,
  .square-box-benefits {
    width: 100%;
    max-width: 320px;
  }

  .circle {
    margin-bottom: 10px;
  }
} */
/* .image-container {
  width: 100%;
  position: relative;
  overflow: hidden; }

.benefit-image {
  width: 100%;
  height: 150px; 
  margin: 0 auto;
} */
/* .image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 15px;
} */
