/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@media (min-width: 780px) {
  .after-hero-section {
    /* background-color: bisque; */
    background-color: #fff6e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .image-section img {
    width: 120%;
    height: 100%;
  }
  .text-section h2 {
    font-family: "Poppins", sans-serif;
    color: rgba(45, 42, 42, 0.918);
    text-transform: uppercase;
  }
  .text-section p {
    /* width: 200%; */
    width: 100%;
    font-weight: 400;
    font-size: large;
    font-family: "Poppins", sans-serif;
  }
  .text-section button {
    border-radius: 120px;
    color: white;
    background-color: #023047;
  }
  .text-section button:hover {
    /* border-radius: 120px; */
    outline: #023047 1px solid;
  }
  .image-section {
    margin-top: 35px;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1880px) {
  .after-hero-section {
    margin: 0 auto;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .after-hero-section {
    background-color: #fff6e5;
    /* background-color: bisque; */
    display: flex;
    margin-bottom: 40px;
    margin-left: -40px;
    margin-right: 50px;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;
  }
  .image-section {
    margin-top: 0;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 50px; */
  }
  .image-section img {
    width: 50vw;
    height: 38vw;
    /* width: 255px;
    height: 180px; */
  }
  .text-section {
    margin-left: 2px;
  }
  .text-section h2 {
    font-family: "Poppins", sans-serif;
    color: rgba(45, 42, 42, 0.918);
    text-transform: uppercase;
    margin-bottom: 12px;

    font-size: 1.5em;
    /* width: 220%; */
    margin: 0;
  }
  .text-section-p {
    font-size: medium;
    width: unset;
    /* width: 320%; */
    margin: 0;
  }
  .text-section button {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 140px;
    border-radius: 180px;
    color: white;
    background-color: #023047;
    > span {
      font-size: 10px;
    }
  }
}
