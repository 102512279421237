.summary-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  flex: 1;
  min-width: 200px;
  /* background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  width: 300px; Adjust as needed */
}

.summary-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.summary-card-header {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
}
.summary-card-title {
  font-weight: 600;
}
.summary-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex: 1; */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* color: white; */
}

.summary-card-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.summary-card-value {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  /* margin-top: 10px; */
}
