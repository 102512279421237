.ability-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.p-field-radiobutton {
  margin-bottom: 30px;
  flex-direction: column;
  justify-content: flex-start;
}
.p-radiobutton-icon {
  background-color: rgb(137, 126, 105) !important;
}
/* .ability-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
}

.ability-selection-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.select-container {
  width: 100%;
  text-align: center;
}

.ability-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.ability-select:focus {
  border-color: #007bff;
} */
