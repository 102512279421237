body {
  font-size: 14px;
}

.form-group {
  margin: 4px 0 15px 0;
}

.form-group LABEL {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  display: block;
  box-sizing: border-box;
  height: 34px;
  width: 400px;
  padding: 6px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control.hf-focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.has-error .form-control.hf-focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

#error-info {
  border: 1px solid #ff8c8c;
  padding: 10px;
  margin: 15px 0;
  background-color: #ffdfdf;
}

#error-info H5 {
  margin: 0 0 10px 0;
  padding: 0;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-right: 20px;
  width: 100%;
  margin-top: 3px;
}

.input-with-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.65;
  right: 25px;
}
.input-with-icon input:focus + i {
  display: none;
}
.input-with-icon input:not(:placeholder-shown) + i {
  display: none;
}
@media only screen and (max-width: 768px) {
  .input-with-icon input {
    font-size: 10px;
  }
  .input-with-icon input::placeholder {
    font-size: 12px;
  }
}
