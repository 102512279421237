/* MoneyBackGuarantee.css */

.money-back-guarantee {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 40%; /* Default width on large screens */
  margin: 20px;
  min-width: 300px; /* Ensures the component has a minimum width */
  height: auto; /* Adjust height based on content */
}

.money-back-guarantee h2 {
  color: #333;
  margin-bottom: 10px;
}

.money-back-guarantee p {
  font-size: 16px;
  color: #555;
}

.money-back-guarantee ul {
  list-style-type: disc;
  padding-left: 20px;
}

.money-back-guarantee li {
  margin-bottom: 10px;
  font-size: 12px;
}

/* Responsive design for small screens */
@media (max-width: 768px) {
  .money-back-guarantee {
    flex: 1 1 100%; /* Stacks vertically on small screens */
    margin: 10px 0;
  }
}

/* .money-back-guarantee {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40%;
  margin: 20px auto;
  height: 400px;
}

.money-back-guarantee h2 {
  color: #333;
  margin-bottom: 10px;
}

.money-back-guarantee p {
  font-size: 16px;
  color: #555;
}

.money-back-guarantee ul {
  list-style-type: disc;
  padding-left: 20px;
}

.money-back-guarantee li {
  margin-bottom: 10px;
  font-size: 16px;
} */
