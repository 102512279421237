.jobAssign-modal-instructor-input-column {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  padding: 5px;
  width: 230px;
}
.jobAssign-modal-price-hour-input-column {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  padding: 5px;
  width: 130px;
}
.jobAssign-modal-payable-input-column {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  padding: 5px;
  width: 180px;
}
