/* -----------------------------------------------------------------Vouchers--------------------------------------------------------- */

.voucherHeader {
  -webkit-print-color-adjust: exact !important;
  text-align: center;
  color: white;
  background-color: #9acd32;
  font-weight: 700 !important;
  padding: 5px 0px 5px 0px;
  border-radius: 7px;
}

.textarea-notes {
  background-attachment: local;
  background-image: linear-gradient(to right, white 10px, transparent 10px),
    linear-gradient(to left, white 10px, transparent 10px),
    repeating-linear-gradient(
      white,
      white 30px,
      #ccc 30px,
      #ccc 31px,
      white 31px
    );
  line-height: 31px;
  padding: 5px 10px;
}

.companyDetails {
  font-size: 0.8rem;
}
.hidden {
  display: none !important;
}
.popper-inner,
.pro-inner-list-item {
  background-color: white !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit !important;
}
.p {
  margin-bottom: 0rem !important;
}
.p-dropdown-items {
  padding: 5px !important;
}
.p-card,
.card {
  /* border-radius: 20px !important; */
}
.form-box-rounded {
  border-radius: 20px !important;
  width: 15%;
}
.form-box-logo {
  width: 12%;
}
.whatsapp-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
/* Without Border radius */
.button-primary-square {
  color: white;
  background-color: #023047;
  border: 1px solid #023047;
  height: fit-content;
}
.button-primary-square:hover {
  color: #023047;
  background-color: white;
  border-color: #023047;
}
.button-primary-square-inverse {
  color: #023047;
  background-color: white;
  border: 1px solid #023047;
  border-radius: 32px;
  height: fit-content;
}
.button-primary-square-inverse:hover {
  color: white;
  background-color: #023047;
  border: 1px solid #023047;
}
.button-secondary-square {
  color: white;
  background-color: #219ebc;
  border: 1px solid #219ebc;
  height: fit-content;
}
.button-secondary-square:hover {
  background-color: white;
  color: #219ebc;
}
.button-secondary-square-inverse {
  color: #219ebc;
  background-color: white;
  border: 1px solid #219ebc;
  height: fit-content;
}
.button-secondary-square-inverse:hover {
  background-color: #219ebc;
  color: white;
}
.button-tertiary-square {
  color: white;
  background-color: #fb8500;
  border: 1px solid #fb8500;
  height: fit-content;
}
.button-tertiary-square:hover {
  background-color: white;
  color: #fb8500;
}
.button-tertiary-square-inverse {
  color: #fb8500;
  background-color: white;
  border: 1px solid #fb8500;
  height: fit-content;
}
.button-tertiary-square-inverse:hover {
  background-color: #fb8500;
  color: white;
}
.button-quaternary-square {
  color: white;
  background-color: #ffb703;
  border: 1px solid #ffb703;
  height: fit-content;
}
.button-quaternary-square:hover {
  background-color: white;
  color: #ffb703;
}
.button-quaternary-square-inverse {
  color: #ffb703;
  background-color: white;
  border: 1px solid #ffb703;
  height: fit-content;
}
.button-quaternary-square-inverse:hover {
  background-color: #ffb703;
  color: white;
}
.button-quinary-square {
  color: white;
  background-color: #8ecae6;
  border: 1px solid #8ecae6;
  height: fit-content;
}
.button-quinary-square:hover {
  background-color: white;
  color: #8ecae6;
}
.button-quinary-square-inverse {
  color: #8ecae6;
  background-color: white;
  border: 1px solid #8ecae6;
  height: fit-content;
}
.button-quinary-square-inverse:hover {
  background-color: #8ecae6;
  color: white;
}
/* --with border radius */
.button-primary {
  color: white;
  background-color: #023047;
  border: 1px solid #023047;
  /* font-weight: 700;
  padding: 5px 0px 5px 0px; */
  border-radius: 32px;
  height: fit-content;
  /* background-color: #9acd32;
  border-color: #9acd32; */
}
.button-primary:hover {
  color: #023047;
  background-color: white;
  border-color: #023047;
}
.button-primary-inverse {
  color: #023047;
  background-color: white;
  border: 1px solid #023047;
  border-radius: 32px;
  height: fit-content;
}
.button-primary-inverse:hover {
  color: white;
  background-color: #023047;
  border: 1px solid #023047;
}

.button-secondary {
  color: white;
  background-color: #219ebc;
  border: 1px solid #219ebc;
  border-radius: 32px;
  height: fit-content;
}
.button-secondary:hover {
  background-color: white;
  color: #219ebc;
}
.button-secondary-inverse {
  color: #219ebc;
  background-color: white;
  border: 1px solid #219ebc;
  border-radius: 32px;
  height: fit-content;
}
.button-secondary-inverse:hover {
  background-color: #219ebc;
  color: white;
}
.button-tertiary {
  color: white;
  background-color: #fb8500;
  border: 1px solid #fb8500;
  border-radius: 32px;
  height: fit-content;
}
.button-tertiary:hover {
  background-color: white;
  color: #fb8500;
}
.button-tertiary-inverse {
  color: #fb8500;
  background-color: white;
  border: 1px solid #fb8500;
  border-radius: 32px;
  height: fit-content;
}
.button-tertiary-inverse:hover {
  background-color: #fb8500;
  color: white;
}
.button-quaternary {
  color: white;
  background-color: #ffb703;
  border: 1px solid #ffb703;
  border-radius: 32px;
  height: fit-content;
}
.button-quaternary:hover {
  background-color: white;
  color: #ffb703;
}
.button-quaternary-inverse {
  color: #ffb703;
  background-color: white;
  border: 1px solid #ffb703;
  border-radius: 32px;
  height: fit-content;
}
.button-quaternary-inverse:hover {
  background-color: #ffb703;
  color: white;
}
.button-quinary {
  color: white;
  background-color: #8ecae6;
  border: 1px solid #8ecae6;
  border-radius: 32px;
  height: fit-content;
}
.button-quinary:hover {
  background-color: white;
  color: #8ecae6;
}
.button-quinary-inverse {
  color: #8ecae6;
  background-color: white;
  border: 1px solid #8ecae6;
  border-radius: 32px;
  height: fit-content;
}
.button-quinary-inverse:hover {
  background-color: #8ecae6;
  color: white;
}
.color-primary {
  color: #023047;
}
.color-secondary {
  color: #219ebc;
}
.color-tertiary {
  color: #fb8500;
}
.color-quaternary {
  color: #ffb703;
}
.color-quinary {
  color: #8ecae6;
}
.background-primary {
  background-color: #023047;
}
.background-secondary {
  background-color: #219ebc;
}
.background-tertiary {
  background-color: #fb8500;
}
.background-quaternary {
  background-color: #ffb703;
}
.background-quinary {
  background-color: #8ecae6;
}
.text-primary {
  color: #023047;
}
.text-secondary {
  color: #219ebc;
}
.text-tertiary {
  color: #fb8500;
}
.blog-detail-card {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  size: 16px;
}
.text-quaternary {
}
.vl {
  border-left: 1px solid rgb(255, 255, 255);
  height: auto;
}
.rating-vertical-line {
  height: 11rem;
  width: 0px;
  margin: 0px;
  padding: 0px;
  border-left: 1px solid black;
}
.text-justify {
  text-align: justify;
}
.justify-content-md-start-sm-center {
  justify-content: start;
}
.p-highlight {
  /* background-color: black; */
}
.p-focus {
  /* background-color: initial !important; */
}
.p-menuitem-content {
  background-color: initial !important;
}
.custom-file-input {
  position: relative;
  display: inline-block;
}

.file-input-hidden {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.file-name-display {
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  background-color: #f5f5f5;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .row-md {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}
@media only screen and (max-width: 768px) {
  .vl {
    border-left: 0px;
    border-top: 1px solid rgb(255, 255, 255);
  }
  .row-sm {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

/* form box styles for desktop */
/* @media only screen and (min-width: 600px) {
  .form-box-rounded {
    border-radius: 20px !important;
  }
  .form-box-rounded .form-box-content {
    padding: 20px;
  }
}
.color-tertiary{
  color: #fb8500;
}
.color-quaternary{
  color: #ffb703;
}
.color-quinary{
  color: #8ecae6;
}
  /* form box styles for desktop */
/* @media only screen and (min-width: 600px) {
  .form-box-rounded {
    border-radius: 20px !important;
    width: 15%;
}
} */
/* form box styles for mobile */
@media only screen and (max-width: 600px) {
  .form-box-rounded {
    border-radius: 20px !important;
    width: 75%;
  }
  .form-box-logo {
    width: 60%;
  }

  .justify-content-md-start-sm-center {
    justify-content: center;
  }
}
@media print {
  .printHide {
    display: none !important;
  }
  #capture {
    margin: 0px !important;
  }
}
