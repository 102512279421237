/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100..900&display=swap"); */

.hero-section-root-container {
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  /* background: linear-gradient(-45deg, #219ebc, #fb8500, #023047, #8ecae6); */
  background: linear-gradient(90deg, #023047, #8ecae6);
  /* background: radial-gradient(circle, #023047, #219ebc, #8ecae6); */
  /* background: linear-gradient(90deg, #023047, #8ecae6, #219ebc); */
  /* background: linear-gradient(45deg, #fb8500, #8ecae6); */
  /* background: repeating-conic-gradient(from 90deg, #fb8500 10%, #8ecae6 20%); */
  background-size: 400% 400%;
  /* animation: gradient 45s ease infinite; */
  /* height: 100vh; */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* justify-content: space-between; */
}
.names {
  margin-top: 60px;
  font-size: 30px;
  margin-bottom: 16px;
  height: max-content;
  width: 100%;
  color: gray;
  background-color: white;
  align-items: center;
  justify-content: space-evenly;
}

.hero-text {
  flex: 1;
  padding: 20px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.hero-text h1 {
  font-size: 5.5rem;
  color: white;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.hero-text p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: medium;
}
.hero-text button {
  border-radius: 140px;
  margin-top: -50px;
  font-family: "Poppins", sans-serif;
}
.hero-text span {
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 400;
  margin-bottom: 50px;
  margin-left: -35px;
}
.hero-container {
  display: flex;
  align-items: center;
}
.postal-code {
  width: 700px;
  height: 55px;
  border-radius: 300px;
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  display: flex;
}
.mail-input {
  align-items: center;
  justify-content: center;
  background-image: url("../Assets/Union.png");
  background-size: 15px;
  background-position: 10px center;
  background-repeat: no-repeat;
  padding-left: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  height: 40px;
  width: 350px;
  box-sizing: border-box;
  margin: 0 auto;
  font-size: 20px;
}
.mail-input::placeholder {
  font-size: 20px;
  font-family: "Inter", sans-serif;
}
.vertical-line {
  height: 40px;
  width: 0.5px;
  background-color: rgb(151, 151, 151);
  margin-left: -90px;
  margin-right: 10px;
}
.postal-button {
  align-items: center;
  width: 200px;
  background-color: orange;
  color: white;
  margin-bottom: -50px;
  margin-right: 19px;
}
.postal-button:hover {
  background-color: rgb(255, 255, 255);
  color: orange;
  outline: 1px solid rgb(32, 32, 32);
}
.hero-image-container {
  flex: 1;
  background-color: orange;
  border-radius: 50px;
  border-bottom-left-radius: 800px;
}
@media (min-width: 768px) {
  .hero-image {
    width: 800px;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .hero-text {
    width: 100%;
  }
  .hero-image {
    width: 100%;
    /* width: 80vw; */
    height: max-content;
  }
  .hero-text h1 {
    width: 100%;
    font-size: 3.5rem;
    padding: 0;
  }
  .hero-container {
    display: none;
  }
  .secondary-hero-text {
    margin: 0;
  }
  .hero-container-mobile {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: -40px;
  }
  .hero-container-mobile button {
    font-size: 0.8em;
    width: 130px;
    height: 50px;
    margin-right: 0px;
  }
  .hero-container-mobile span {
    font-size: 0.8em;
  }

  .postal-code {
    display: none;
  }

  .mail-input {
    justify-content: flex-start;
    margin-right: 20px;
    width: auto;
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .mail-input::placeholder {
    font-size: 15px;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
  }
  .postal-button {
    display: none;
  }
  .mobile-postal-button {
    width: 150px;
    height: 30px;
    background-color: orange;
    font-size: 0.8rem;
    color: white;
    border: none;
    outline: none;
  }
  .names {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .hero-text span {
    margin-left: -25px;
  }
}
@keyframes blinkGradient {
  0% {
    background: linear-gradient(45deg, #023047, #fb8500);
  }
  25% {
    background: linear-gradient(45deg, #fb8500, #219ebc);
  }
  50% {
    background: linear-gradient(45deg, #219ebc, #ffb703);
  }
  75% {
    background: linear-gradient(45deg, #ffb703, #8ecae6);
  }
  100% {
    background: linear-gradient(45deg, #8ecae6, #023047);
  }
}

@keyframes textContrast {
  0%,
  100% {
    color: #ffffff;
  } /* White text when background is dark */
  25% {
    color: #000000;
  } /* Black text when background is light */
  50% {
    color: #023047;
  } /* Dark blue text when background is medium */
  75% {
    color: #ffffff;
  } /* White again when background darkens */
}

.animate-blink {
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  text-align: center;
  transition: transform 2s ease-in-out, box-shadow 2s ease-in-out;
  animation: blinkGradient 10s infinite alternate,
    textContrast 10s infinite alternate;
  position: relative;
  display: inline-block;
}

/* Ensure background animation applies correctly */
.animate-blink::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  z-index: -1;
  animation: blinkGradient 10s infinite alternate;
}

/* Mobile tap effect */
.animate-blink:active {
  transform: scale(0.95);
  filter: brightness(1.1);
  box-shadow: 0 0 15px rgba(255, 183, 3, 0.5);
}
