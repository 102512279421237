.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-right: 20px; /* Adjust as needed */
  width: 100%;
  margin-top: 3px;
}

.input-with-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.65;
  right: 25px; /* Adjust as needed */
}
.input-with-icon input:focus + i {
  display: none;
}
.input-with-icon input:not(:placeholder-shown) + i {
  display: none;
}
.promo-container {
  /* background-color: #023047; */
  padding: 5px 5px;
  border-radius: 8px;
  margin: 8px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer !important;
}

.promo-text-gradient-blink {
  font-size: 1rem;
  font-weight: bold;
  background: linear-gradient(90deg, #8ecae6, #219ebc, #ffb703, #fb8500);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientMove 8s linear infinite, blink 2s infinite;
  display: inline-block;
}

.promo-text-gradient-blink i {
  color: #fb8500; /* Orange for the icon */
  /* margin-right: 10px; */
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  /* 75% {
    opacity: 0.75;
  } */
  50% {
    opacity: 0.5;
  }
}
/* .promo-text-container {
  background-color: #023047; 
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.promo-container {
  background-color: #023047; 
  padding: 15px 20px;
  border-radius: 8px;
}

.promo-text-gradient {
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(90deg, #8ecae6, #219ebc, #ffb703, #fb8500);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientMove 8s linear infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */

/* .promo-text-blinking {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffb703;
  background: linear-gradient(90deg, #219ebc, #fb8500, #ffb703);
  background-size: 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientBlinking 3s ease-in-out infinite,
    glowingText 1.5s ease-in-out infinite;
}

@keyframes gradientBlinking {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}

@keyframes glowingText {
  0% {
    text-shadow: 0 0 10px #ffb703, 0 0 20px #fb8500;
  }
  50% {
    text-shadow: 0 0 20px #fb8500, 0 0 40px #ffb703;
  }
  100% {
    text-shadow: 0 0 10px #ffb703, 0 0 20px #fb8500;
  }
} */

@media only screen and (max-width: 768px) {
  .postcode-outer-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(90deg, #219ebc, #fb8500, #ffb703); */
    background-size: 300%;
    /* animation: gradientMove 8s linear infinite; */
  }
  .post-code-main-div {
    width: 80%;
  }
  .input-with-icon input {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  .input-with-icon input::placeholder {
    font-size: 1rem;
  }
}
