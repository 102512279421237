.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: auto;
}

.slider-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.slider-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-item {
  min-width: 16.66%;
  box-sizing: border-box;
  padding: 1px;
}

.slider-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.slider-item img:hover {
  transform: scale(1.05);
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.slider-button.prev {
  left: 10px;
}

.slider-button.next {
  right: 10px;
}
