.root-div {
  /* margin-left: 250px; */
}
.new-custom-listing .p-paginator-bottom {
  /* float: inline-end; */
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #e4e6eb;
}
/* .p-paginator .p-component */
.new-custom-listing .p-dropdown-items {
  padding: 0px;
}
.new-custom-listing .p-dropdown-panel {
  /* width: max-content; */
}
.new-custom-listing .p-dropdown {
  /* width: 100px; */
  margin-left: 10px;
  margin-right: 10px;
  /* border: 1px solid #e4e6eb;
  border-radius: 20px; */
  /* /* padding: 0px 0px 1px 0px; */
}
/* .new-custom-listing .p-dropdown .p-dropdown-label{
  padding: 10px;
  border-radius: 90px;
  border: 1px solid #e4e6eb;
}
.new-custom-listing .p-dropdown .p-dropdown-trigger{
  padding: 10px;
  border-radius: 90px;
} */
@media (max-width: 768px) {
  .root-div {
    margin-left: 0px;
  }
}
.root-div {
  /* margin-left: 200px; */
  /* margin-left: 250px; */
  /* width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* margin-top: 50px; */
}
/* .root-div-collapsed {
  margin-left: 90px;
  width: 90%;
} */
