// $primary-color: white;
// $primary-color: #031e33;
// $primary-color: #eeae1e;
$primary-color: #023047;
// $primary-color: #bbaeae;

$primary-color-light: #ffbf0f;
$complementory-color: #f14705;
// $secondary-color: #bf1515;
$secondary-color: #000000;

$tertiary-color: white;
$info-color: #33b5e5;

$warning-color: rgba(255, 183, 0, 0.3);
$danger-color: rgba(255, 0, 0, 0.3);

$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

/* -----------------------------------------------------------------form-fields--------------------------------------------------------- */
$form-field-height: 33px !default;

/* -----------------------------------------------------------------sidebar--------------------------------------------------------- */

$sidebar-bg-color: white;
// $sidebar-bg-color: $primary-color;
// $sidebar-color: #031e33;
$sidebar-color: black;
$sidebar-width: 205px;
$sidebar-collapsed-width: 54px;
// $highlight-color: #000000;
$highlight-color: $primary-color;
// $submenu-bg-color: rgba(3, 30, 51, 1);
// $submenu-bg-color: rgba(3, 30, 51, 1);
// $submenu-bg-color-collapsed: $secondary-color;
$submenu-bg-color-collapsed: $primary-color-light;
$icon-bg-color: rgba(255, 255, 255, 0);
$icon-size: 35px;

@import "~react-pro-sidebar/dist/scss/styles.scss";
