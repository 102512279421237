/* Common styles for all cards */
.wp-card-container {
  width: 25rem;
  margin: 1rem;
  > .wp-course-card {
    height: 400px; /* Set a fixed height for all cards */
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: space-between;
    border-top-right-radius: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 5px;
  }
}
.wp-course-card:hover {
  /* transform: scale3d(1.05, 1.05, 1.05); */
  /* transform: skew(2deg, 1deg); */
}
/* Geometrical shapes (common) */
/* .wp-card-container::before,
.wp-card-container::after {
  content: "";
  position: absolute;
  background: linear-gradient(45deg, #fb8500, #ffb703);
  opacity: 0.8;
}
.wp-card-container::before {
  top: -10px;
  right: -10px;
  width: 100px;
  height: 100px;
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
}
.wp-card-container::after {
  bottom: -10px;
  left: -10px;
  width: 150px;
  height: 150px;
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
} */
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.wp-course-category-heading {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  text-align: center;
  background-color: #023047;
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background: linear-gradient(
    270deg,
    #8ecae6,
    #219ebc,
    #023047,
    #ffb703,
    #fb8500
  );
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 5s ease infinite; */
}
.wp-course-category-content {
  font-size: large;
  font-family: "Inter", sans-serif;
}
/* Styles based on category */
.wp-course-card.Intermediate {
  background-color: #8ecae6;
  /* background-color: #8ecae6; */
  /* background-color: #ffb703; */
}
.wp-course-card.Beginner .wp-course-button {
  background-color: #ffb703;
}
.wp-course-card.Beginner .wp-course-button:hover {
  background-color: #fb8500;
}

.wp-course-card.Beginner {
  background-color: #219ebc;
}
.wp-course-card.Intermediate .wp-course-button {
  background-color: #fb8500;
}
.wp-course-card.Intermediate .wp-course-button:hover {
  background-color: #ffb703;
}

.wp-course-card.Refresher {
  background-color: #023047;
}
.wp-course-card.Refresher .wp-course-button {
  background-color: #ffb703;
}
.wp-course-card.Refresher .wp-course-button:hover {
  background-color: #fb8500;
}

.wp-card-content {
  overflow-y: scroll;
  cursor: text;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Number of lines to show */
  -webkit-box-orient: vertical;
  line-clamp: 6;
  box-orient: vertical;
  height: 100px; /* Adjust height to match the content area */
}
/* --------- benifits section -------- */
.courses-benefits-section {
  font-family: "Inter", sans-serif;
  /* padding: 40px 20px; */
  /* background-color: #f9f9f9; Light background for contrast */
  border-radius: 8px; /* Rounded corners */
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); Soft shadow */
}

.courses-benifits-section-title {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 600;
  color: #333; /* Darker color for better readability */
}

.courses-benefits-item {
  background: #fbffff; /* White background for each item */
  border: 1px solid #e0e0e0; /* Light grey border */
  border-radius: 12px; /* Rounded corners */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth hover effect */
  padding: 20px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  position: relative; /* For hover effect */
}

.courses-benefits-item:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  background-color: #f7fdff;
}

.courses-benifits-image-container {
  width: 100%;
  max-height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 8px; /* Rounded corners for images */
  /* background: #ececec; Placeholder background for image container */
}

.courses-benefit-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.courses-benifits-text-card-heading {
  font-weight: 700; /* Slightly less bold */
  font-size: 1.7rem; /* Responsive font size */
  margin-bottom: 15px;
}

.courses-benifits-text-card {
  font-size: 1rem;
  color: #555; /* Darker grey for text */
  text-align: justify;
}

@media (max-width: 768px) {
  .benefits-section {
    padding: 20px;
  }

  .benefits-item {
    width: 100%; /* Full width on smaller screens */
  }
}
