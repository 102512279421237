/* FaqPage.css */
.faq-container {
  max-width: 800px;
  margin: auto;
  /* padding-bottom: 25px;
  padding-top: 25px; */
}

.faq-text-center {
  text-align: center;
  font-family: "Arial", sans-serif;
  color: #000000;
}

.faq-container .p-accordion .p-accordion-header .p-accordion-header-link {
  color: #0b222c;
  font-weight: bold;

  padding-bottom: 20px;
  padding-top: 20px;
}

.faq-container .p-accordion .p-accordion-content {
  background-color: #f8f9fa;
}
