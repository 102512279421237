.learner-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.learner-card {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  width: 600px;
  height: max-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border: 2px solid #8ecae6;
  /* padding: 15px; */
  margin: 10px;
  align-items: center;
  justify-content: space-between;
}

.learner-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #023047;
  margin-bottom: 8px;
  flex-grow: 1;
}
.learner-card .p-card-body {
  width: 100%;
}
.learner-contact-icons {
  display: flex;
  justify-content: end;
  gap: 10px;
}
.horizantal-line {
  margin: 0.2rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}
.contact-icon {
  color: #023047;
  font-size: 14px;
  /* background-color: lightgray; */
  padding: 5px;
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 5px 0;
}

.learner-address,
.learner-postcode {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.learner-address p,
.learner-postcode p {
  /* color: #023047; */
  font-size: 17px;
  margin: 0;
}

.icon {
  margin-right: 5px;
}

.empty-card-container {
  display: flex;
  justify-content: end;
  align-items: end;
  /* margin: 1rem; */
  margin-right: 30px;
  /* position: fixed; */
}

.empty-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border: 2px solid #8ecae6; /* Border color for contrast */
}

.empty-card:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.empty-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #333;
}
@media (max-width: 768px) {
  .learner-card {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .learner-card {
    width: 300px;
  }
}
