/* src/components/ServicesPage.css */
.services-page {
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.services-page h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.service-card {
  width: 300px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.service-card:hover {
  transform: scale(1.05);
}

.service-icon {
  font-size: 3rem;
  color: #007ad9;
  margin-bottom: 1rem;
}

.service-content h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.service-content p {
  font-size: 1rem;
  color: #666;
}
