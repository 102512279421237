@import "./variables.scss";
// @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

/* -----------------------------------------------------------------text-colors------------------------------------------------------------ */

.color-primary,
.color-Primary {
  color: $primary-color !important;
}

.color-primary-light {
  // color: $primary-color-light !important;
  color: $secondary-color !important;
}

.color-secondary,
.color-Secondary {
  color: $secondary-color !important;
}

.color-tertiary {
  color: $tertiary-color !important;
}

.color-Info {
  color: $info-color !important;
}

/* -----------------------------------------------------------------on-hover-text-colors------------------------------------------------------------ */

.hover-primary:hover,
.hover-Primary:hover {
  color: $primary-color !important;
}

.hover-primary-light:hover {
  color: $primary-color-light !important;
}

.hover-Info:hover {
  color: $info-color !important ;
}

.hover-secondary:hover,
.hover-Secondary:hover {
  color: $secondary-color !important;
}

.hover-tertiary:hover {
  color: $tertiary-color !important;
}

.bg-hover-primary:hover,
.bg-hover-Primary:hover {
  background-color: $primary-color !important;
}

.bg-hover-primary-light:hover {
  background-color: $primary-color-light !important;
}

.bg-hover-secondary:hover,
.bg-hover-Secondary:hover {
  background-color: $secondary-color !important;
}

.bg-hover-tertiary:hover {
  background-color: $tertiary-color !important;
}

.bg-hover-transparent:hover {
  background: transparent !important;
}

.bg-hover-Info:hover {
  background-color: $info-color !important;
}

/* -----------------------------------------------------------------background-colors------------------------------------------------------------ */

.bg-Primary {
  background-color: $primary-color !important;
}
.bg-Secondary {
  background-color: $secondary-color !important;
}
.bg-primary-light {
  background-color: $primary-color-light !important;
}
.bg-tertiary {
  background-color: $tertiary-color !important;
}
.bg-grey {
  // background-color: #e6e6e6 !important;
  background-color: #fafafa !important;
}
.bg-warn {
  background-color: $warning-color !important;
}
.bg-Danger {
  background-color: $danger-color !important;
}
.bg-Info {
  background-color: $info-color !important;
}

/* -----------------------------------------------------------------borders------------------------------------------------------------ */

.primary-border,
.Primary-border {
  border: 2px solid $primary-color !important;
}

.secondary-border,
.Secondary-border {
  border: 2px solid $secondary-color !important;
}

.primary-light-border {
  border: 2px solid $primary-color-light !important;
}

.Info-border {
  border: 2px solid $info-color !important;
}

.tertiary-border {
  border: 2px solid $tertiary-color !important;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.border-none {
  border: none !important;
}

//for testing purposes
.b1 {
  border: 2px solid red !important;
}

.b2 {
  border: 2px solid $primary-color-light !important;
}

.b3 {
  border: 2px solid green !important;
}

/* -----------------------------------------------------------------inputs------------------------------------------------------------ */

.z-index-3 {
  z-index: 3 !important;
}

.h-38 {
  height: 38px !important;
}

//hide inner spin button from input type number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//input switch
.p-inputswitch {
  height: 23px !important;
  width: 40px !important;
  vertical-align: middle;
}
.p-inputswitch .p-inputswitch-slider:before {
  height: 19px !important;
  width: 19px !important;
  margin-top: -0.6rem !important;
  left: 2px !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.1rem) !important;
}

//rounded border-radius buttons with icons
.p-button-rounded.with-icon-button {
  font-weight: 600 !important;
  // border-radius: 25px;

  //if icon position is right
  // .p-button-label {
  //     margin: 0px 20px 0px 0px !important;
  // }
  .pi {
    font-size: 14px !important;
  }
}

//icon-buttons
.icon-button,
.p-row-editor-save,
.p-row-editor-cancel,
.p-row-editor-init,
.p-row-editor-delete {
  padding: 0px;
  border: 0 none;
  border-radius: 25px !important;
  &:hover {
    transform: scale(1.25, 1.25);
  }
  .pi {
    font-size: 17px;
    &:not(.pi-trash) {
      color: $primary-color;
      // &:hover {
      //     color: $secondary-color;
      // }
    }
    &.pi-trash {
      color: $secondary-color;
    }
  }
}

//all prime-icons in app
.pi {
  font-weight: 900 !important;
}

//icon in search input
.pi-search {
  color: $primary-color !important;
}

//hover select options
.select-option:hover {
  background-color: #ddd;
}

.custom-input {
  border: none !important;
  box-shadow: none !important;
  .p-inputtext {
    border: none !important;
    box-shadow: none !important;
  }
  &:not(.p-inputtextarea) {
    height: $form-field-height !important;
  }
  &.p-disabled,
  .p-disabled {
    opacity: 1 !important;
  }
}
.custom-checkbox {
  .p-disabled {
    opacity: 1 !important;
  }
}

// custom-prime-multi-select
.p-multiselect.custom-multi-select {
  height: $form-field-height !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  // font-weight: 500;
  .p-multiselect-trigger,
  .p-multiselect-close {
    color: $primary-color-light !important;
  }
  .p-multiselect-label {
    .p-multiselect-token {
      background-color: $primary-color;
      color: $tertiary-color;
      border-radius: 0px !important;
      font-weight: 500;
      .p-multiselect-token-icon {
        color: rgb(221, 135, 135);
      }
    }
    padding: 5px 0px 0px 8px !important;
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    color: $primary-color-light !important;
    padding-top: 3px !important;
    &.p-placeholder {
      padding-top: 7px !important;
    }
  }
  .p-multiselect-item,
  .p-multiselect-header {
    color: $primary-color !important;
    font-weight: 600;
    .p-checkbox-box,
    .p-checkbox-box.p-focus {
      border-color: $primary-color;
      box-shadow: none !important;
      &.p-highlight {
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }
  }
  &.p-disabled {
    opacity: 1 !important;
  }
}

// custom-prime-select
.p-dropdown.custom-dropdown {
  height: $form-field-height !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  padding: 0px 0px 0px 3px !important;
  width: 100%;
  .p-dropdown-label {
    font-size: 14px !important;
    text-align: left;
  }
  .p-dropdown-trigger {
    padding-top: 3px !important;
    color: $primary-color;
  }
  .p-dropdown-item {
    color: $primary-color !important;
  }
  &.color-primary-light {
    .p-dropdown-label {
      color: $primary-color-light !important;
      font-weight: bold;
    }
    .p-dropdown-trigger {
      color: $primary-color-light !important;
    }
  }
}
.custom-dropdown-panel {
  .p-dropdown-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

.custom-button,
.submit-button {
  outline: none !important;
  text-transform: capitalize;
  border-radius: 3px;
  font-weight: 600 !important;
  font-size: 14px;
  letter-spacing: 0.6px;
  &.p-button-text.p-button-icon-only:hover {
    transform: scale(1.25);
  }
}

.action-button {
  outline: none !important;
  border: none !important;
  height: 38px !important;
  color: $tertiary-color;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.7px;
}

.action-button.p-button-sm {
  height: 33px !important;
}

// .p-button {
//     box-shadow: none !important;
// }

.float-label {
  color: $primary-color-light !important;
  font-weight: bold !important;
  font-size: 13px;
  padding-left: 3px;
  padding-top: 2px;
}

.p-inputtext:focus + .float-label,
.p-inputwrapper-focus + .float-label,
.p-inputtext[data-focus-visible-added] + .float-label {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 11px !important;
  background-color: white !important;
  margin-top: 0px !important;
  padding: 4px 4px 0px !important;
}

/* -----------------------------------------------------------------datepicker------------------------------------------------------------ */
.p-calendar,
.custom-calendar {
  &.form-field {
    .p-inputtext {
      height: $form-field-height !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      border: none !important;
    }
  }
  .p-button-icon-only {
    height: 38px;
    border-radius: 0px !important;
  }
}
.p-datepicker.custom-datepicker {
  .p-datepicker-calendar-container {
    max-height: 24rem !important;
    table {
      margin: 0px !important;
      thead {
        th {
          text-align: center !important;
          font-weight: 700 !important;
        }
      }
      td {
        padding: 0.4rem 0.1rem !important;
        font-weight: 500 !important;
      }
    }
    .p-datepicker-today > span {
      color: $tertiary-color !important;
      background-color: $primary-color !important;
    }
    .p-highlight {
      color: $tertiary-color !important;
      background-color: $primary-color-light !important;
    }
  }
  .p-datepicker-buttonbar {
    padding: 0px !important;
  }
  .p-timepicker {
    padding: 0px !important;
    button {
      height: 2rem !important;
      width: 2rem !important;
    }
    span {
      font-size: 16px;
    }
  }
  button > .p-button-label {
    font-weight: 600 !important;
  }
}

/* -----------------------------------------------------------------p-dialog------------------------------------------------------------ */
.p-dialog {
  // .p-dialog-header,
  // .p-dialog-content,
  .p-dialog-footer {
    background-color: $primary-color !important;
  }
  .p-dialog-content.overflow-visible {
    overflow: visible !important;
  }
  .p-dialog-content.loading {
    overflow: hidden !important;
  }
  .p-dialog-footer {
    padding: 0px !important;
  }
}

/* -----------------------------------------------------------------Listbox------------------------------------------------------------ */
.custom-listbox {
  padding: 0px !important;
  // border: none !important;
  .p-highlight {
    // background-color: $primary-color !important;
    background-color: $secondary-color !important;
    color: $tertiary-color !important;
    border: none !important;
  }
  .p-listbox-item {
    font-size: 14px;
    // border-bottom: 1px solid grey !important;
    &:not(.p-highlight) {
      // color: $primary-color !important;
      color: $secondary-color !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.custom-tabmenu {
  .p-tabmenuitem {
    .p-menuitem-link {
      font-size: 14px;
      color: $primary-color !important;
      box-shadow: none !important;
    }
    &.active {
      .p-menuitem-link {
        color: $tertiary-color !important;
        background-color: $primary-color !important;
      }
    }
  }
}

//button in all modal headers
.modal-header > button {
  color: $tertiary-color !important;
  font-size: 30px;

  &:hover {
    color: $secondary-color !important;
  }
}

/* -----------------------------------------------------------------Header------------------------------------------------------------ */

@media (max-width: 960px) {
  header {
    .custom-header {
      // position: fixed !important;
      justify-content: space-between;
      .p-menubar-button {
        color: $tertiary-color !important;
        &:hover {
          color: $primary-color !important;
        }
      }
      ul {
        background-color: $primary-color !important;
        padding: 10px 15px !important;
        li {
          border-left: none !important;
          a,
          a > i {
            color: $tertiary-color !important;
            padding: 3px 0px !important;
            background-color: transparent !important;
            box-shadow: none !important;
            &:not(i) {
              font-weight: 500;
              padding: 8px 0px !important;
            }
            &:hover {
              color: $primary-color-light !important;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 961px) {
  header {
    .custom-header {
      justify-content: space-between;
      box-shadow: none !important;
      ul {
        li {
          a,
          a > i {
            color: $secondary-color !important;
            padding: 0px 4px !important;
            box-shadow: none !important;
            &:not(i) {
              font-weight: 500;
              padding: 0px 10px !important;
            }
            &:hover {
              color: $primary-color !important;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}

/* -----------------------------------------------------------------misc------------------------------------------------------------ */

.cursor-help:hover {
  cursor: help;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.font-light-bold {
  font-weight: 500;
}

.h-100vh {
  min-height: 100vh !important;
}
.h-50vh {
  min-height: 50vh !important;
}

.outline-none {
  outline: none !important;
}

.screen-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // min-height: 100vh !important;/
}

.tooltip,
.p-tooltip-text {
  border: 2px solid $primary-color-light;
  color: $tertiary-color !important;
  background: $primary-color !important;
  opacity: 1 !important;
  padding: 7px;
  .popover_arrow {
    display: none;
  }
}

.tooltip-width {
  min-width: 215px !important;
}

.validating-label {
  font-weight: bold;
  letter-spacing: 0.8px;
  font-family: monospace;
  color: red;
}

// .invalid-tooltip {
//     display: block !important;
// }

.was-validated .p-inputtext:invalid {
  // .was-validated :invalid ~ .invalid-tooltip {
  border: 1px solid red !important;
  // display: block !important;
}

//
body {
  margin: 0;
  height: 100vh;
  font-family: "Montserrat", sans-serif !important;
  // font-family: "Roboto", sans-serif !important;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;
  // background-color: $secondary-color !important;

  main {
    // padding-top: 58px !important;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    > img.main-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.3;
      object-fit: cover;
      object-position: center;
      left: 0;
      top: 0;
      // z-index:1;
    }

    @media (min-width: $breakpoint-sm) {
      .w-sm-25 {
        width: 25% !important;
      }

      .w-sm-50 {
        width: 50% !important;
      }

      .w-sm-75 {
        width: 75% !important;
      }

      .w-sm-100 {
        width: 100% !important;
      }

      .h-sm-100vh {
        min-height: 100vh;
      }
      .h-sm-50vh {
        min-height: 50vh;
      }
    }

    @media (min-width: 767.98px) {
      .w-md-25 {
        width: 25% !important;
      }

      .w-md-50 {
        width: 50% !important;
      }

      .w-md-75 {
        width: 75% !important;
      }

      .w-md-100 {
        width: 100% !important;
      }
    }

    @media (min-width: 991.98px) {
      .w-lg-25 {
        width: 25% !important;
      }

      .w-lg-50 {
        width: 50% !important;
      }

      .w-lg-75 {
        width: 75% !important;
      }

      .w-lg-100 {
        width: 100% !important;
      }
    }
  }
}
@media print {
  .print-hide,
  ::-webkit-scrollbar {
    display: none !important;
  }

  #capture {
    margin: 0px !important;
    padding: 0px !important;
  }
}

@media (max-width: $breakpoint-sm) {
}

@media (max-width: 767.98px) {
  // overflow-x: hidden;
}

/* -----------------------------------------------------------------scroll-bar------------------------------------------------------------ */
/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $tertiary-color;
  border-radius: 9px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color-light;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0b4775;
}

/* -----------------------------------------------------------------bootstrap-classes---------------------------------------------------- */
.w-100 {
  width: 100% !important;
}
.w-75 {
  width: 75% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
.h-100 {
  height: 100% !important;
}
.h-75 {
  height: 75% !important;
}
.h-50 {
  height: 50% !important;
}
.h-25 {
  height: 25% !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.animated {
  -webkit-animation-duration: 1s !important;
  animation-duration: 1s !important;
  -webkit-animation-fill-mode: both !important;
  animation-fill-mode: both !important;
}
.fadeInDown {
  animation-name: fadeInDown !important;
}
.small {
  font-size: 80% !important;
  font-weight: 400 !important;
}
.fixed-top {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  z-index: 1030 !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

// ----------------------------------------------------------------------------------------
.custom-select-container .dropdown-value {
  text-transform: uppercase; // Ensure text is displayed in uppercase
  margin-right: 0; // Adjust margins as needed
  padding-right: 0; // Adjust padding as needed
}

.custom-select-container .p-dropdown {
  display: flex; // Ensure flexbox is used
  align-items: center; // Center items vertically
}

.custom-select-container .p-dropdown-label {
  flex: 1; // Ensure the label takes up the remaining space
}
