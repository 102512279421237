/* @media (min-width: 780px) {
  .home {
    margin: 0;
    margin-right: 200px;
    margin-left: 200px;
    margin-top: -25px;
  }
}
@media (max-width: 768px) {
  .home {
    margin: 0 auto;
    margin-right: auto;
    margin-left: -20px;
    margin-top: -25px;
  }
} App.css */
.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  overflow-x: hidden;
}

.main-content {
  /* max-width: 100%;
  margin: 0 auto;
  padding: 0 15px; */
  flex-grow: 1;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  /* margin-left: 250px; Adjust for sidebar width */
  /* padding: 20px; */
  /* background-color: #f2f1f0; */
  transition: margin-left 0.3s ease;
  overflow: hidden;
}
.switch-toggle {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
/* 
.main-content.collapsed {
  margin-left: 80px;} */

/* .sidebar {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  background-color: #1f2937;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 80px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #111827;
}

.sidebar-header .logo {
  max-width: 100px;
}

.sidebar.collapsed .logo {
  max-width: 40px;
}

.sidebar-header .collapse-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.sidebar-content {
  flex-grow: 1;
  padding: 20px 0;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  margin-bottom: 20px;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.sidebar-link:hover {
  background-color: #374151;
}

.sidebar-icon {
  margin-right: 15px;
  font-size: 20px;
}

.sidebar.collapsed .sidebar-icon {
  margin-right: 0;
  text-align: center;
  width: 100%;
}

.sidebar-label {
  transition: opacity 0.3s ease;
}

.sidebar.collapsed .sidebar-label {
  opacity: 0;
  pointer-events: none;
}

.sidebar-footer {
  padding: 20px;
  background-color: #111827;
}

.sidebar-footer .sidebar-link {
  color: #f87171;
}

.sidebar-footer .sidebar-link:hover {
  background-color: #374151;
} */

@media only screen and (max-width: 768px) {
  .app {
    flex-direction: column;
  }

  .main-content {
    margin-left: 0;
    padding: 0px;
    width: 100%;
  }

  .sidebar {
    display: none;
    /* width: 100%;
    height: auto;
    position: relative; */
  }

  .sidebar.collapsed {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .app {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .sidebar {
    display: none;
    /* width: 100%;
    height: auto;
    position: relative; */
  }
}

/* @media only screen and (max-width: 768px) {
  .app {
    max-width: 768px;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 400px) {
  .app {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
} */
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */
