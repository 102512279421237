.fullcalendar-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  /* border: 1px solid #ddd;  */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.fc {
  --fc-event-bg-color: #007bff; /* Primary color for events */
  --fc-event-text-color: #fff; /* Text color for events */
  --fc-today-bg-color: #f0f8ff; /* Background color for today’s date */
  --fc-today-border-color: #007bff; /* Border color for today’s date */
}

.fc .fc-daygrid-day-number {
  font-size: 0.9rem; /* Smaller font size for day numbers */
  color: darkslategray;
  text-decoration: none;
}
.fc .fc-daygrid-day-number a {
  color: #000000; /* Link color for day numbers */
}
.fc .fc-col-header-cell-cushion {
  color: #000000; /* Link color for day numbers */
  text-decoration: none;
}
.fc-event-past {
  background-color: grey !important;
  color: white;
}

.fc-event-future {
  background-color: #219ebc !important;
  color: white;
}

/* 
.fc .fc-daygrid-day.fc-day-today {
  background-color: #f0f8ff; /* Background color for today’s date */
/* }  */
.fc .fc-daygrid-day-top {
  padding: 2px; /* Reduced padding */
}

.fc .fc-daygrid-day {
  border-radius: 8px; /* Rounded corners for days */
}

.fc .fc-daygrid-day:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Highlight effect on hover */
}

.fc .fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullcalendar-container .fc-button {
  text-transform: capitalize;
}
/* Responsive styles */
@media (max-width: 768px) {
  .fullcalendar-container {
    font-size: 0.9rem; /* Adjust font size for better readability on smaller screens */
  }
}

@media (max-width: 480px) {
  .fullcalendar-container {
    font-size: 0.8rem; /* Reduce font size on very small screens */
  }
}
