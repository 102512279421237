/* @media (min-width: 780px) {
  .home {
    margin: 0;
    margin-right: 200px;
    margin-left: 200px;
    margin-top: -25px;
  }
} */
@media (max-width: 768px) {
  .home {
    margin: 0 auto;
    margin-right: auto;
    margin-left: -20px;
    margin-top: -25px;
  }
}
@media (max-width: 576px) {
  /* For extra small screens */
  .home {
    margin-left: -45px;
  }
}
