.beginner-container {
  width: 350px;
  height: auto;
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* background-color: #b71313; */
  padding: 34px;
  outline: 1px #ffa600 solid;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #333;
  font-size: 18px;
}
.beginner-container span {
  color: #023047;
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 29px;
}
.beginner-container p {
  font-size: 16px;
  width: 65%;
  height: 40%;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
}
.beginner-container button {
  margin-top: 20px;
  margin-bottom: 80px;
  border-radius: 90px;
  color: white;

  background-color: #ffa600;
  font-size: 14px;
  outline: none;
}
.beginner-container button:hover {
  outline: #ffa600 1px solid;
}
.intermediate-container {
  width: 350px;
  margin: 10px;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #b71313; */
  padding: 34px;
  outline: 1px #023047 solid;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #333;
  font-size: 18px;
}
.intermediate-container span {
  color: #023047;
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 20px;
  margin-top: 29px;
  text-align: center;
}
.intermediate-container p {
  font-size: 16px;
  width: 70%;
  height: 40%;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
}
.intermediate-container button {
  margin-top: 20px;
  margin-bottom: 80px;
  border-radius: 90px;
  color: white;

  background-color: #023047;
  font-size: 14px;
}
.intermediate-container button:hover {
  outline: #023047 1px solid;
}
.refresher-container {
  width: 350px;
  margin: 10px;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #b71313; */
  padding: 34px;
  outline: 1px #219ebc solid;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #333;
  font-size: 18px;
}
.refresher-container span {
  color: #023047;
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 20px;
  margin-top: 29px;
  text-align: center;
}
.refresher-container p {
  font-size: 16px;
  width: 75%;
  height: 40%;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
}
.refresher-container button {
  margin-top: 20px;
  margin-bottom: 80px;
  border-radius: 90px;
  color: white;

  background-color: #219ebc;
  font-size: 14px;
}
.refresher-container button:hover {
  outline: #219ebc 1px solid;
}
