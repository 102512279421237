.course-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.course-card {
  background-color: #023047;
  border: 2px solid transparent;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(103, 60, 60, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s, border-color 0.3s;
  color: white;
  width: 200px;
  text-align: center;
  padding: 10px;
}

.course-card.selected {
  border-color: #219ebc;
  transform: scale(1.05);
}

.course-card:hover {
  transform: scale(1.05);
  border-color: #007ad9;
}

.course-card-content {
  padding: 10px;
}

.course-card-content h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #007ad9;
}

.course-card-content p {
  margin: 5px 0;
  font-size: 16px;
  color: #fffdfd;
}
.transmission-selected {
  background-color: rgb(255, 250, 175);
}
.user-category-icon {
  width: 24px;
  height: 24px;
}
.userCategory-selected {
  background-color: rgba(248, 246, 220, 0.274);
}

.course-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 32px;
  align-items: center;
  justify-content: center;
}

/* .course-card {
  background: #f2eece;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 16px);
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
} */
.course-card.selected {
  background: orange;
}

.course-card h3 {
  margin: 0 0 8px 0;
  font-size: 1.2rem;
}

.course-card p {
  font-weight: bold;
  margin: 4px 0;
}
.gear-choice {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 32px;
  background-color: #d9c885;
  height: 70px;
  border-radius: 90px;
  font-weight: 600;
  color: rgb(5, 55, 38);
}
.continue-button {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 35px;
  background-color: rgb(222, 150, 61);
  font-weight: bold;
}
.course-description {
  /* border: #000000 2px 2px solid; */
  width: 75%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 32px;
  font-size: 1.2rem;
  /* font-weight: 600;  */
  color: rgb(5, 55, 38);
  border-style: solid;
  border-color: rgba(255, 166, 0, 0.533);
}
.course-description p:first-of-type {
  font-weight: 650;
  color: rgb(5, 55, 38);
}
.course-description h3 {
  margin: 0 0 8px 0;
  font-size: 1.2rem;
}
h4 {
  margin: 25px;
}
h3 {
  margin: 25px;
}
/* 
.gear-choice h2 {
  margin-bottom: 16px;
}

.gear-choice .gear-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
} */
@media (max-width: 768px) {
  .transmission-type {
    font-size: 0.9rem;
  }
  .driving-text {
    font-size: 1.05rem;
  }
  .user-category {
    font-size: 0.85rem;
    align-items: center;
    justify-content: center;
  }
  .user-category-icon {
    width: 18px;
    height: 18px;
  }
  .course-card {
    background-color: #023047;
    border: 2px solid transparent;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(103, 60, 60, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s, border-color 0.3s;
    color: white;
    width: 200px;
    text-align: center;
    padding: 10px;
    /* background: #f2eece;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* width: calc(46.333% - 1px);  */
    /* Adjust the width according to your design */
    /* cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; */
  }
  .course-description {
    /* border: #000000 2px 2px solid; */
    width: 95%;
    border-radius: 10px;
    /* padding: 10px; */
    margin-bottom: 0px;
    font-size: 1.2rem;
    /* font-weight: 600;  */
    color: rgb(5, 55, 38);
    border-style: solid;
    border-color: rgba(255, 166, 0, 0.533);
    font-size: 0.8rem;
  }
}
