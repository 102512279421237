.status-new {
  /* background-color: #9584bd; */
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  /* border: 1px solid #6226ef; */
  /* color: #6226ef;  */
  color: black;
  background-color: #d9e5ff;
  /* color: white; */
  font-weight: bold;
  text-align: center;
  width: 100px;
  /* background-color: #6226ef; */
}
.status-processing {
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  width: 100px;
  color: black;
  background-color: #fff3d4;
  /* background-color: #48bb78; */
  /* background-color: #fff3d4; */
}
.status-completed {
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  width: 100px;
  color: black;
  background-color: #dff5e4;
  /* background-color: #fbbf24; */
  /* background-color: #dff5e4; */
}
.status-pending {
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  width: 100px;
  color: black;
  background-color: #ffe5e5;
  /* background-color: #fbbf24; */
  /* background-color: #ffe5e5; */
}
