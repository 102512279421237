.payment-page {
  padding: 20px;
  background-color: #f4f4f4;
}

.p-d-flex {
  display: flex;
}

.p-jc-end {
  justify-content: flex-end;
}

.p-button-outlined {
  margin: 0 5px;
}
