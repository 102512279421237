.payment-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
}

.payment-step-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.input-container {
  width: 100%;
  margin-bottom: 15px;
}

.payment-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.payment-input:focus {
  border-color: #007bff;
}
.payment-container {
  background: linear-gradient(185deg, #219ebc, #023047);
  /* background: linear-gradient(145deg, #219ebc, #023047); */
}

.payment-step-title {
  color: #4a5568;
}

.input-container label {
  font-weight: 600;
}

.StripeElement {
  color: white;
  border-radius: 0.5rem;
  border: 1px solid #cbd5e0;
  padding: 0.75rem;
  margin-top: 0.5rem;
  transition: box-shadow 0.3s ease;
}

.StripeElement--focus {
  border-color: #3182ce;
  box-shadow: 0 0 5px rgba(49, 130, 206, 0.5);
}
