.components-container {
  max-width: "65%";
  align-items: "center";
  justify-content: "center";
  margin: "0 auto";
}
.outer-stepper-div {
  border: none;
  width: 50%;
  margin-left: 25%;
}
.stepper-div {
  width: 50%;
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */

  margin: 0 auto;
}
.p-overlaypanel {
  position: relative;
  /* width: 100px; */
  padding: 5px 5px 1px 5px;
}
.order-summary-container {
  /* background-color: #343a40; */
  background-color: bisque;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 350px;
  /* width: max-content; */
  justify-content: space-between;
  top: 180px;
  right: 10vw;
  position: fixed;
  z-index: 10;
  /* height: 12vw; */
  height: fit-content;
  /* height: 50%; */
  /* height: calc(70vh - 130px); */
  /* overflow-y: auto; */
}

.order-summary-card {
  background: #023047;
  border: none;
}

.section-title {
  color: #adb5bd;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.order-summary-section {
  margin-top: 20px;
}

.btn-deposit {
  background-color: #ffca28;
  border: none;
  color: #343a40;
}

.btn-full-payment {
  background-color: #28a745;
  border: none;
  color: white;
}

@media only screen and (max-width: 768px) {
  .components-container {
    display: none;
  }
  .outer-stepper-div {
    width: 100%;
    margin-left: 0px;
  }
  .stepper-div {
    align-items: center;
    width: 100%;
    > nav > ol {
      margin: 0px;
      padding: 0;
    }
  }
  .order-summary-container {
    position: unset;
    color: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    /* width: max-content; */
    justify-content: space-between;
    top: 880px;
    /* bottom: 700px; */
    right: 0vw;
    /* height: 12vw; */
    height: fit-content;
  }
  .components-container-mobile {
    /* width: 300%; */
    /* margin: 0;
    padding: 0;
    height: 100%; */
  }
}

@media only screen and (max-width: 400px) {
  .p-dialog .p-dialog-content {
    padding: 1px;
  }
}
/* @media only screen and (max-width: 600px){}
@media only screen and (max-width: 480px){}
@media only screen and (max-width: 320px){}

@media only screen and (min-width: 768px){}
@media only screen and (min-width: 600px){}
@media only screen and (min-width: 480px){} */
