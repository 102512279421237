/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap"); */
.page-title-container {
  padding: 10px;
}
.c-card-container {
  width: 25rem;
  margin: 0.3rem;
}
.card-title {
  display: flex;
  margin: 0;
  flex-direction: column;
  /* align-items: center; */
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-family: "Inter", sans-serif;
}

.title-section {
  height: 10rem;
  /* width: 10rem; */
  width: 20%;
  margin-right: "150px";
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: 900;
  color: black;
  span:nth-of-type(2) {
    font-weight: 700;
    color: #023047;
  }
}

.title-icon {
  height: 5rem;
  width: 5rem;
  margin-top: 40px;
  /* display: flex; */
  /* align-items: flex-start; */
  margin-right: 10px;
}

.title-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-duration {
  margin-top: 5px;
}
.side-details {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: #ffe7ba;
  margin-bottom: 15px;
  flex-grow: 0.65;
  flex-basis: 0;
  padding: 0.5rem;
  > p {
    /* margin-top: 20px; */
    font-size: 30pt;
    font-weight: 800;
    font-family: "Poppins", sans-serif;
  }
  /* > Link > button {
    color: white;
    background-color: #023047;
    border-radius: 32px;
  } */
}
.detail-course-card {
  background-color: rgb(251, 251, 251);
  /* background-color: #023047; */
  border: 1px solid;
  /* border-style: dotted; */
  /* border-color: #023047; */
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(103, 60, 60, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s, border-color 0.3s;
  color: black;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  text-align: center;
  padding: 10px;
}
.detail-course-card.selected {
  border-color: #219ebc;
  transform: scale(1.05);
}

.detail-course-card:hover {
  transform: scale(1.05);
  border-color: #023047;
}

.detail-course-card-content {
  padding: 10px;
}

.detail-course-card-content h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #007ad9;
}

.detail-course-card-content p {
  margin: 5px 0;
  font-size: 16px;
  color: #fffdfd;
}
.cc-refresher {
  /* background-image: linear-gradient(
    to top right,
    #2e2860 0%,
    #023047 50%,
    #4d5a92 100%
  ); */
  border-color: #219ebc;
  color: #219ebc;
  /* color: #023047; */
}

.cc-beginner {
  /* background-image: linear-gradient(
    to bottom left,
    #316f8f 0%,
    #3b4c7e 50%,
    #8a98c7 100%
    ); */
  border-color: #37a5d8;
  color: #37a5d8;
  /* color: #ffb703; */
  /* color: #3b4c7e; */
}

.cc-intermediate {
  /* background-image: linear-gradient(
      to top right,
      #8ecae6 0%,
      rgba(33, 158, 188, 0.9) 50%,
      rgba(4, 102, 122, 1) 100%
      ); */
  border-color: #023047;
  color: #023047;
  /* color: rgba(33, 158, 188, 0.9); */
}
.book-now {
  color: white;
  background-color: #023047;
  border-radius: 32px;
}
.book-now:hover {
  outline: #023047;
}
.ccb-beginner {
  background-color: #37a5d8;
}
.ccb-beginner:hover {
  outline: #37a5d8 1px solid;
  color: white;
}
.ccb-beginner-inverse {
  color: white;
  outline: #37a5d8 1px solid;
}
.ccb-beginner-inverse:hover {
  background-color: #37a5d8;
  color: white;
}
.ccb-intermediate {
  background-color: #023047;
  /* background-color: #086391; */
}
.ccb-intermediate:hover {
  outline: #023047 1px solid;
  color: white;
}
.ccb-intermediate-inverse {
  color: white;
  outline: #023047 1px solid;
}
.ccb-intermediate-inverse:hover {
  background-color: #023047;
  color: white;
}

.ccb-refresher {
  background-color: #219ebc;
}
.ccb-refresher:hover {
  outline: #219ebc 1px solid;
  color: white;
}

.ccb-refresher-inverse {
  color: white;
  /* color: #219ebc; */
  outline: #219ebc 1px solid;
}
.ccb-refresher-inverse:hover {
  background-color: #219ebc;
  color: white;
}
.cc-card-content {
  overflow: hidden; /* Hide the overflowed text */
  text-overflow: ellipsis; /* Add ellipsis when text overflows */
  display: -webkit-box; /* Required for line clamping */
  word-break: break-word;
  -webkit-line-clamp: 5; /* Limit the number of lines to 6 */
  -webkit-box-orient: vertical; /* Ensure vertical box layout */
  box-orient: vertical; /* Fallback for non-Webkit browsers */
  height: 120px; /* Adjust the height to accommodate 6 lines of text */
}
/* .cc-card-content {
  overflow-y: hidden;
  cursor: text;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  line-clamp: 6;
  box-orient: vertical;
  height: 100px;} */
.Additional-Options {
  margin: 10px;
  padding: 10px;
  background-color: #fff6e5;
  border-radius: 30px;
}
.radio-buttons-container {
  /* margin-left: 1vw; */

  margin: 0vw 0vw 1vw 2vw;
}

.side-title {
  margin: 20px;
  font-weight: 700;
}
switch {
  margin-left: 200px;
}
#other-courses {
  font-weight: 600;
  font-size: 32pt;
}
@media (min-width: 780px) {
  .card-container {
    justify-content: center;
    margin-left: 20px;
  }
}
@media (max-width: 768px) {
  .radio-buttons-container {
    margin: 3vw 0vw 5vw 5vw;
  }
  .detail-course-card {
    height: max-content;
    width: 100%;
    margin: 1rem 0;
  }
}
