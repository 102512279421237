/* src/components/Payments.css */
.payments-container {
  padding: 2rem;
}

.payment-card {
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-details {
  padding: 1rem;
}

.payment-details p {
  margin: 0.5rem 0;
}

.payment-details strong {
  font-weight: bold;
}
