.recommender-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.recommender-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.questions {
  margin-bottom: 20px;
}

.recommender-container h2 {
  font-size: 20px;
  margin-bottom: 10px;
}
.CRbutton-next {
  /* background-color: #4caf50; */
  /* background-color: #086391; */
  background-color: #023047;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
}
.CRbutton {
  /* background-color: #4caf50; */
  /* background-color: #086391; */
  color: #023047;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
}

.CRbutton:hover {
  color: white;
  background-color: #023047;
  /* background-color: #45a049; */
}

.recommend-button {
  display: block;
  margin: 20px 0;
  background-color: #023047;
}
.recommender-inner-container {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
.recommendation {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.recommendation p {
  margin: 5px 0;
}
