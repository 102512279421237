.card-container {
  width: 25rem;
  margin: 1rem;
  > .course-card {
    height: 400px; /* Set a fixed height for all cards */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.card-content {
  overflow-y: scroll;
  cursor: text;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Number of lines to show */
  -webkit-box-orient: vertical;
  line-clamp: 6;
  box-orient: vertical;
  height: 100px; /* Adjust height to match the content area */
}
.course-button {
  margin-top: 20px;
  margin-bottom: 80px;
  border-radius: 90px;
  color: white;
  font-size: 14px;
  outline: none;
}
.p-card-title {
  text-align: start;
  /* display: flex;
    jus */
}
.p-card-subtitle {
  text-align: start;
}
.beginner {
  background-color: #ffa600;
}
.beginner:hover {
  outline: #ffa600 1px solid;
  color: white;
}

.intermediate {
  /* background-color: #023047; */
  background-color: #086391;
}
.intermediate:hover {
  outline: #086391 1px solid;
  color: white;
}

.refresher {
  background-color: #219ebc;
}
.refresher:hover {
  outline: #219ebc 1px solid;
  color: white;
}
.beginner-inverse {
  /* color: #ffa600; */
  color: white;
  outline: #ffa600 1px solid;
}
.beginner-inverse:hover {
  background-color: #ffa600;
  color: white;
}

.intermediate-inverse {
  color: #086391;
  color: white;
  outline: #086391 1px solid;
}
.intermediate-inverse:hover {
  background-color: #086391;
  color: white;
}

.refresher-inverse {
  color: white;
  /* color: #219ebc; */
  outline: #219ebc 1px solid;
}
.refresher-inverse:hover {
  background-color: #219ebc;
  color: white;
}

@media (max-width: 768px) {
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .course-card {
      height: max-content;
      /* min-height: 400px; */
      /* height: ; */
      /* height: 30vh; */
      width: 100%;
      margin: 1rem 0;
    }
  }

  .card-content {
    height: auto;
  }
  .course-button {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  /* width: 100%;
  } */
}
