/* .p-dialog {
  width: fit-content;
  height: fit-content;
} */
.dialog-field-div {
  display: flex;
  align-items: center;
  margin: 25px;
}
/* .dialog-input {
  width: 50%;
  height: 30px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
} */
.dialog-checkbox {
  margin-left: 8px;
}
.dialog-button {
  border-radius: 25px;
  background-color: #fb8500;
  border: none;
  color: white;
  padding: 5px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 14px 2px 12px;
  cursor: pointer;
}
.dialog-button:hover {
  background-color: #ffffff;
  color: #fb8500;
  border: 1px solid #fb8500;
}
