.contact-us {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  font-family: Arial, sans-serif;
}

.contact-us h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.contact-us p {
  color: #666;
  font-size: 18px;
  margin-bottom: 20px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 18px;
}

.contact-item i {
  color: #007bff;
  margin-right: 10px;
  font-size: 24px;
}

.contact-item a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-item a:hover {
  color: #0056b3;
}
